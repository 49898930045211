import React, { useState, useEffect, useContext } from "react"
import styled from "styled-components"
import { Link as GatsbyLink } from "gatsby"
import axios from "axios"
import { useTranslation } from "react-i18next"
import { BrinkContext } from "../components/context/BrinkContext"
import {
  containerMaxWidth,
  MEDIA_MIN_MEDIUM,
  MEDIA_MIN_LARGE
} from "../constants"
import Layout from "../components/Layout"
import Link from "../components/Link"
import Loader from "../components/ui/Loader"
import Button from "../components/ui/Button"
import Search from "../components/zendesk/Search"
import SearchResult from "../components/zendesk/SearchResult"
import SectionButtons from "../components/zendesk/SectionButtons"
import SectionArticles from "../components/zendesk/SectionArticles"
import Breadcrumbs from "../components/ui/Breadcrumbs"

const Container = styled.div`
  max-width: ${containerMaxWidth};
  margin: 0 auto;
  padding: 2.5rem;
`

const Title = styled.h1`
  margin-top: 3rem;
  padding: 0 3rem;
  text-align: center;
  font-size: 2.4rem;
  line-height: 3rem;

  ${MEDIA_MIN_MEDIUM} {
    font-size: 3.2rem;
  }
`

const Contact = styled.div`
  margin: 0 auto;
  padding: 2rem 0 6rem;
  text-align: center;

  ${MEDIA_MIN_MEDIUM} {
    padding: 2rem 0 8rem;
  }

  p {
    margin-top: 0.5rem;
  }
`

const ContactButton = styled(Button)`
  margin: 0 auto;
  background: ${(p) => p.theme.colors.secondary};
  width: auto;
  padding: 0 3rem 0.1rem;

  i {
    font-size: 2rem;
    margin-right: 1rem;
  }

  ${MEDIA_MIN_LARGE} {
    &:hover {
      background: ${(p) => p.theme.colors.secondaryOpac};
    }
  }
`

const CustomerServicePage = ({ pageContext }) => {
  const { t } = useTranslation("translations")
  const { isLoading, setIsLoading, languageCode } = useContext(BrinkContext)
  const [selectedSection, setSelectedSection] = useState(null)
  const [searchResult, setSearchResult] = useState(null)
  const [sections, setSections] = useState()
  const [articles, setArticles] = useState()
  const locale =
    languageCode === "sv"
      ? "sv-se"
      : languageCode === "en"
      ? "en-us"
      : languageCode === "fr"
      ? "fr-fr"
      : languageCode

  setIsLoading(true)

  useEffect(() => {
    const headers = {
      Authorization: `Basic ${process.env.GATSBY_ZENDESK_API_TOKEN}`
    }

    axios
      .get(`${process.env.GATSBY_ZENDESK_API_URL}/${locale}/sections.json`, {
        headers
      })
      .then((response) => {
        setSections(response.data.sections)
        setSelectedSection(360004816800)
      })
      .catch((error) => {
        setSections({ errorMessage: error.message })
        console.error("There was an error!", error)
      })

    axios
      .get(`${process.env.GATSBY_ZENDESK_API_URL}/${locale}/articles.json`, {
        headers
      })
      .then((response) => setArticles(response.data.articles))
      .catch((error) => {
        setArticles({ errorMessage: error.message })
        console.error("There was an error!", error)
      })
  }, [setIsLoading, locale])

  if (sections && articles) {
    setIsLoading(false)
  }

  return (
    <Layout
      meta={{ title: t("Customer service") }}
      invertedHeader
      pageContext={pageContext}
      slug={`customer-service`}
    >
      <Breadcrumbs>
        <Link to="/customer-service/">{t("Customer service")}</Link>
      </Breadcrumbs>
      <Container>
        <Title>{t("Customer service")}</Title>
        <Search searchResult={searchResult} setSearchResult={setSearchResult} />
        {searchResult ? (
          <SearchResult searchResult={searchResult} />
        ) : (
          sections &&
          articles && (
            <>
              <Loader isLoading={isLoading} />
              <SectionButtons
                sections={sections}
                selectedSection={selectedSection}
                setSelectedSection={setSelectedSection}
              />
              <SectionArticles
                articles={articles}
                selectedSection={selectedSection}
              />
            </>
          )
        )}
        <Contact>
          <strong>{t("Can't find your question?")}</strong>
          <p>{t("Get in touch with us and we will help you!")}</p>
          <GatsbyLink to="/contact/">
            <ContactButton>
              <i className="fal fa-paper-plane"></i> {t("Contact us")}
            </ContactButton>
          </GatsbyLink>
        </Contact>
      </Container>
    </Layout>
  )
}

export default CustomerServicePage
