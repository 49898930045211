import React from "react"
import styled from "styled-components"
import ScrollTo from "react-scroll-into-view"
import { MEDIA_MIN_MEDIUM } from "../../constants"

const Container = styled.div`
  max-width: 100rem;
  margin: 1rem auto;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  > div {
    width: 100%;

    ${MEDIA_MIN_MEDIUM} {
      width: calc(33% - 2rem);
      margin: 0 1rem;
    }
  }

  ${MEDIA_MIN_MEDIUM} {
    margin: 3rem auto;
  }
`

const Section = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 5rem;
  padding: 0.2rem 0;
  margin: 1rem 0;
  background: ${(p) => (p.selected ? p.theme.colors.black : "none")};
  border: 0.1rem solid
    ${(p) => (p.selected ? "none" : p.theme.colors.lightBorder)};
  color: ${(p) => p.theme.colors.black};
  text-transform: uppercase;
  font-size: 1.4rem;
  cursor: pointer;

  ${MEDIA_MIN_MEDIUM} {
    height: 7rem;
    font-size: 1.6rem;

    &:hover {
      background: ${(p) => p.theme.colors.black};
      border: 0;
    }
  }

  i {
    font-size: 2rem;
    margin-right: 1.2rem;
    padding-top: 0.2rem;

    ${MEDIA_MIN_MEDIUM} {
      font-size: 2.4rem;
    }
  }
`

const SectionButtons = ({ sections, selectedSection, setSelectedSection }) => {
  if (!sections) return null

  const getIcon = (section) => {
    switch (section) {
      // FAQ
      case 360004816800:
        return <i className="fal fa-question-circle"></i>
      // Sustainability
      case 360004859979:
        return <i className="fal fa-recycle"></i>
      // Delivery
      case 360004816840:
        return <i className="fal fa-shipping-fast"></i>
      // Returns
      case 360004816860:
        return <i className="fal fa-undo-alt"></i>
      // Order
      case 360004816820:
        return <i className="fal fa-file-alt"></i>
      // Payment
      case 360004859959:
        return <i className="fal fa-money-bill-alt"></i>
      default:
        return null
    }
  }

  return (
    <Container>
      {sections.map((section) => (
        <ScrollTo key={section.id} selector={`.articles`}>
          <Section
            selected={section.id === selectedSection}
            onClick={() => {
              setSelectedSection(
                section.id !== selectedSection ? section.id : selectedSection
              )
            }}
          >
            {getIcon(section.id)}
            {section.name}
          </Section>
        </ScrollTo>
      ))}
    </Container>
  )
}

export default SectionButtons
