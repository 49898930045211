import React from "react"
import styled from "styled-components"
import axios from "axios"
import { useForm, Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { MEDIA_MIN_MEDIUM } from "../../constants"
import Input from "../ui/Input"
import Button from "../ui/Button"

const Container = styled.div`
  max-width: 50rem;
  margin: 5rem auto 2rem;
  position: relative;

  ${MEDIA_MIN_MEDIUM} {
    margin: 5rem auto;
  }
`

const SearchInput = styled(Input)`
  padding-top: 0.1rem;
  border: 0.1rem solid ${(p) => p.theme.colors.lightBorder};

  input {
    text-transform: initial;
  }
`

const SearchButton = styled(Button)`
  font-size: 2rem;
  position: absolute;
  top: 0rem;
  right: 0;
  width: 5.2rem;
  height: 5.2rem;
  padding-top: 0.2rem;

  &:hover {
    background: ${(p) => p.theme.colors.black};
    color: ${(p) => p.theme.colors.white};
  }
`

const ClearButton = styled(SearchButton)``

const Search = ({ searchResult, setSearchResult }) => {
  const { t } = useTranslation("translations")
  const { control, handleSubmit, errors } = useForm({
    mode: "onSubmit"
  })

  const search = (data) => {
    const headers = {
      Authorization: `Basic ${process.env.GATSBY_ZENDESK_API_TOKEN}`
    }

    axios
      .get(
        `${process.env.GATSBY_ZENDESK_API_URL}/articles/search.json?query=${data.searchQuery}`,
        {
          headers
        }
      )
      .then((response) => {
        setSearchResult(response.data)
      })
      .catch((error) => {
        setSearchResult({ errorMessage: error.message })
        console.error("There was an error!", error)
      })
  }

  return (
    <Container>
      <form onSubmit={handleSubmit(search)}>
        <Controller
          as={SearchInput}
          control={control}
          rules={{
            required: t("This is a required field")
          }}
          type="text"
          name="searchQuery"
          placeholder={t("How can we help you?")}
          errors={errors}
        />
        {searchResult ? (
          <ClearButton type="button" onClick={() => setSearchResult(null)}>
            <i className="fal fa-times"></i>
          </ClearButton>
        ) : (
          <SearchButton type="submit">
            <i className="fal fa-search"></i>
          </SearchButton>
        )}
      </form>
    </Container>
  )
}

export default Search
