import React from "react"
import styled from "styled-components"
import { Trans } from "react-i18next"
import { MEDIA_MIN_MEDIUM } from "../../constants"
import Article from "./Article"

const Container = styled.div`
  max-width: 120rem;
  margin: 2rem auto;
  position: relative;

  ${MEDIA_MIN_MEDIUM} {
    margin: 5rem auto;
  }
`

const Text = styled.p`
  text-align: center;
  margin-bottom: 2rem;

  ${MEDIA_MIN_MEDIUM} {
    margin-bottom: 5rem;
  }
`

const Results = styled.ul`
  list-style: none;
  padding: 0;
`

const SearchResult = ({ searchResult }) => {
  const resultCount = searchResult.count
  const string = searchResult.count === 1 ? "article" : "articles"

  return (
    <Container>
      <Text>
        <Trans i18nKey="zendeskSearchQuery">
          Found <strong>{{ resultCount }}</strong> relevant {{ string }}
        </Trans>
      </Text>
      <Results>
        {searchResult.results &&
          searchResult.results.map((article) => (
            <Article key={article.id} article={article} />
          ))}
      </Results>
    </Container>
  )
}

export default SearchResult
